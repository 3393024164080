import { useRef, useState, useCallback, useMemo, useEffect } from "react";

import { isRenderingOnClient } from "./constants";
import { throttle } from "./util";

export function useCounter({ frequency = 1000, immediate } = {}) {
  const [count, setCount] = useState(0);
  const interval = useRef();

  const start = useCallback(
    function start(initialCount) {
      // Reset everything.
      if (typeof initialCount === "number") setCount(initialCount);
      clearInterval(interval.current);

      // Start the counter going.
      interval.current = setInterval(() => {
        setCount((c) => c + 1);
      }, frequency);
    },
    [frequency]
  );
  const pause = useCallback(function pause() {
    clearInterval(interval.current);
  }, []);
  useEffect(() => {
    if (immediate) start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => ({ count, start, pause }), [count, start, pause]);
}

export function useHover() {
  const prevEl = useRef();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = useCallback(() => setIsHovered(true), []);
  const handleMouseOut = useCallback(() => setIsHovered(false), []);

  const callbackRef = useCallback(
    (el) => {
      if (prevEl.current) {
        prevEl.current.removeEventListener("mouseover", handleMouseOver);
        prevEl.current.removeEventListener("mouseout", handleMouseOut);
        setIsHovered(false);
      }

      prevEl.current = el;

      if (prevEl.current) {
        prevEl.current.addEventListener("mouseover", handleMouseOver);
        prevEl.current.addEventListener("mouseout", handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut, setIsHovered]
  );

  return [callbackRef, isHovered];
}

export function useKeyPresses(keyHandlers) {
  const [keysPressed, setKeysPressed] = useState({});

  useEffect(() => {
    function downHandler({ key }) {
      const handlers = keyHandlers[key];
      if (handlers) {
        setKeysPressed((oldKeysPressed) => {
          if (oldKeysPressed[key]) return oldKeysPressed;
          handlers.onDown?.();
          return {
            ...oldKeysPressed,
            [key]: true,
          };
        });
      }
    }

    function upHandler({ key }) {
      const handlers = keyHandlers[key];
      if (handlers) {
        setKeysPressed((oldKeysPressed) => {
          if (!oldKeysPressed[key]) return oldKeysPressed;
          handlers.onUp?.();
          return {
            ...oldKeysPressed,
            [key]: false,
          };
        });
      }
    }

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [keyHandlers]);

  return keysPressed;
}

export function useWindowSize() {
  const [remeasureCount, setRemeasureCount] = useState(0);
  const triggerRemeasure = useCallback(
    () => setRemeasureCount((c) => c + 1),
    [setRemeasureCount]
  );
  const getSize = useCallback(
    () => [
      window.innerWidth,
      window.innerHeight,
      document.documentElement.scrollHeight,
      triggerRemeasure,
    ],
    [triggerRemeasure]
  );
  const [size, setSize] = useState(() =>
    isRenderingOnClient ? getSize() : [1000, 1000, 1000, () => {}]
  );

  useEffect(() => {
    const handleResize = throttle(() => setSize(getSize), 80);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getSize]);

  useEffect(() => {
    setSize(getSize());
  }, [remeasureCount, getSize]);

  return size;
}

export function useMousePosition(el) {
  const [mousePosition, setMousePosition] = useState([null, null]);
  useEffect(() => {
    const handleMouseMove = (e) => setMousePosition([e.pageX, e.pageY]);
    if (el) {
      el.addEventListener("mousemove", handleMouseMove, false);
      return () => {
        el.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [el]);
  return mousePosition;
}

export function useAllImagesLoaded() {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    window.Promise.all(
      Array.from(document.images)
        .filter((img) => !img.complete)
        .map(
          (img) =>
            new window.Promise((resolve) => {
              img.addEventListener("load", resolve);
              img.addEventListener("error", resolve);
            })
        )
    ).then(() => {
      setIsLoaded(true);
    });
  }, []);
  return isLoaded;
}
