const isRenderingOnServer = typeof window === "undefined";
const isRenderingOnClient = !isRenderingOnServer;

const PAGE_TYPES = {
  CHRONICLE_POST: "CHRONICLE_POST",
  CHRONICLE: "CHRONICLE",
  COLOPHON: "COLOPHON",
  CONTEXT: "CONTEXT",
  CONTRIBUTION: "CONTRIBUTION",
  COUNTERFACTUAL_CONTEXT: "COUNTERFACTUAL_CONTEXT",
  COUNTERFACTUAL_POST: "COUNTERFACTUAL_POST",
  COUNTERFACTUAL: "COUNTERFACTUAL",
  CYCLE_CONTEXT: "CYCLE_CONTEXT",
  CYCLE: "CYCLE",
  INDEX: "INDEX",
  NOT_FOUND: "NOT_FOUND",
};

const PAGE_BACKGROUNDS = {
  CHRONICLE_POST: 6,
  CHRONICLE: 5,
  COLOPHON: 9,
  CONTEXT: 10,
  CONTRIBUTION: 3,
  COUNTERFACTUAL_CONTEXT: 8,
  COUNTERFACTUAL_POST: 8,
  COUNTERFACTUAL: 7,
  CYCLE_CONTEXT: 3,
  CYCLE: 2,
  INDEX: 2,
  NOT_FOUND: 2,
};

const PAGE_TYPE_FROM_PATH = {
  "/chronicle/": PAGE_TYPES.CHRONICLE,
  "/colophon/": PAGE_TYPES.COLOPHON,
  "/context/": PAGE_TYPES.CONTEXT,
  "/counterfactual/context/": PAGE_TYPES.COUNTERFACTUAL_CONTEXT,
  "/counterfactual/": PAGE_TYPES.COUNTERFACTUAL,
  "/": PAGE_TYPES.INDEX,
  "/404/": PAGE_TYPES.NOT_FOUND,
};

module.exports = {
  isRenderingOnServer,
  isRenderingOnClient,
  PAGE_TYPES,
  PAGE_BACKGROUNDS,
  PAGE_TYPE_FROM_PATH,
};
