import React, { useRef, useEffect, useState } from "react";

import Nav from "./nav";
import { PAGE_BACKGROUNDS, PAGE_TYPES } from "../util/constants";
import SEO from "./seo";
import GraphQLErrorList from "./graphql-error-list";

import "../styles/global.css";
import "../styles/typography.css";
import * as styles from "./layout.module.css";

import bg1 from "../images/bg-cycle-1.png";
import bg2 from "../images/bg-cycle-2.png";
import bg3 from "../images/bg-cycle-3.png";
import bg4 from "../images/bg-chronicle-1.png";
import bg5 from "../images/bg-chronicle-2.png";
import bg6 from "../images/bg-chronicle-3.png";
import bg7 from "../images/bg-counterfactual-2.png";
import bg8 from "../images/bg-counterfactual-3.png";
import bg9 from "../images/bg-colophon-3.png";
import bg10 from "../images/bg-context-3.png";

const bgs = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10];

const Layout = ({ children, location, pageContext, errors, ...rest }) => {
  const cycleImages = useRef([]);
  const [cycleImagesLoaded, setCycleImagesLoaded] = useState(false);

  useEffect(() => {
    const imagePromises = Array.from({ length: 60 }, (_, i) => {
      const idx = `${i + 1}`.padStart(2, "0");
      const image = new Image();
      return new Promise((resolve, reject) => {
        image.addEventListener("load", () => {
          // Save the image so it’s not removed from the browser cache.
          cycleImages.current.push(image);
          resolve(image);
        });
        image.addEventListener("error", reject);
        image.src = `/images/cycle/c-cycle-${idx}.svg`;
      });
    });
    Promise.all(imagePromises).then(() => {
      setCycleImagesLoaded(true);
    });
  }, []);

  if (errors) {
    return (
      <>
        <SEO title="GraphQL Error" />
        <GraphQLErrorList errors={errors} />
      </>
    );
  }

  const { pageType } = pageContext;
  const hideNav = pageType === PAGE_TYPES.INDEX;
  const bg = PAGE_BACKGROUNDS[pageType] ?? 3;

  const backgroundStyle = {};
  if (typeof bg === "number") {
    backgroundStyle.style = {
      backgroundImage: `url(${bgs[bg - 1]})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "cover",
      backgroundAttachment: "fixed"
    };
  }

  return (
    <div className={styles.content} {...backgroundStyle}>
      {!hideNav && (
        <Nav
          cycleImagesLoaded={cycleImagesLoaded}
          location={location}
          {...backgroundStyle}
        />
      )}
      {children}
    </div>
  );
};

export default Layout;
