import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

const defaultQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      keywords
      twitterName
    }
  }
`;

function SEO({
  description,
  lang = "en",
  meta = [],
  keywords,
  title,
  image,
  authorTwitterName
}) {
  const { site } = useStaticQuery(defaultQuery) || {};

  const siteTitle = site.title || "";
  const metaImage = image?.asset
    ? imageUrlFor(buildImageObj(image)).width(1200).url()
    : "";

  if (!title) title = siteTitle;
  if (!description) description = site.description || "";
  if (!keywords) keywords = site.keywords || [];

  const optionalAttributes = [];
  if (site.twitterName) {
    optionalAttributes.push({
      name: `twitter:site`,
      content: site.twitterName
    });
  }
  if (authorTwitterName) {
    optionalAttributes.push({
      name: `twitter:creator`,
      content: authorTwitterName
    });
  }

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={title === siteTitle ? "%s" : `%s • ${siteTitle}`}
      meta={[
        {
          name: "description",
          content: description
        },
        {
          property: "og:title",
          content: title
        },
        {
          property: "og:description",
          content: description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: metaImage
        },
        {
          name: "twitter:card",
          content: "summary"
        },
        {
          name: "twitter:title",
          content: title
        },
        {
          name: "twitter:description",
          content: description
        },
        ...optionalAttributes
      ]
        .concat(
          keywords && keywords.length > 0
            ? {
                name: "keywords",
                content: keywords.join(", ")
              }
            : []
        )
        .concat(meta)}
    />
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string
};

export default SEO;
