import React, { useCallback, useContext, useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { isFuture } from "date-fns";

import { cn } from "../lib/helpers";
import { useHover, useCounter, useKeyPresses } from "../util/hooks";
import LayoutContext from "../contexts/layout";

import * as styles from "./nav.module.css";
import { boxUl, boxLinkInvert } from "./box-list.module.css";

const query = graphql`
  query NavQuery {
    cycles: allSanityCycle(
      sort: { fields: [publishedAt], order: ASC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          title
          theme
          slug {
            current
          }
        }
      }
    }
  }
`;

const CButton = React.forwardRef(({ cycleIdx }, ref) => {
  const { isNavOpen, setIsNavOpen } = useContext(LayoutContext);
  return (
    <button
      className={styles.toggleNavButton}
      onClick={() => setIsNavOpen((toggle) => !toggle)}
      ref={ref}
      aria-controls="site_nav_menu"
      aria-expanded={isNavOpen}
      aria-haspopup="true"
      aria-label="Toggle navigation"
      data-target="#site_nav_menu"
      data-toggle="collapse"
      id="site_nav_menu_button"
      type="button"
    >
      <img
        alt=""
        src={`/images/cycle/c-cycle-${`${cycleIdx + 1}`.padStart(2, "0")}.svg`}
        height="100%"
        width="100%"
      />
    </button>
  );
});

const Nav = ({ cycleImagesLoaded, location, ...extraProps }) => {
  const { isNavOpen, setIsNavOpen } = useContext(LayoutContext);
  const [cycleRef, isCycleHovered] = useHover();
  const isAnimating = cycleImagesLoaded && (isNavOpen || isCycleHovered);
  const { count, start, pause } = useCounter({ frequency: 42 });
  const cycleIdx = count % 60;

  const onHideNav = useCallback(() => {
    setIsNavOpen(false);
  }, [setIsNavOpen]);

  useEffect(() => {
    const shouldAnimate = !window.matchMedia("(prefers-reduced-motion: reduce)")
      .matches;
    if (shouldAnimate && isAnimating) {
      start();
    } else {
      pause();
    }
  }, [isAnimating, start, pause]);

  useKeyPresses({ Escape: { onDown: onHideNav } });

  const data = useStaticQuery(query);
  const pages = [
    {
      title: "Chthonic",
      href: "/",
      isActive: location.pathname === "/"
    },
    ...data.cycles.edges
      .filter(({ node }) => !isFuture(new Date(node.publishedAt)))
      .map(({ node }) => ({
        title: `${node.title} – ${node.theme}`,
        href: `/${node.slug.current}`,
        isActive: location.pathname.startsWith(`/${node.slug.current}`)
      })),
    {
      title: "Context",
      href: "/context",
      isActive: location.pathname.startsWith("/context")
    },
    {
      title: "Chronicle",
      href: "/chronicle",
      isActive: location.pathname.startsWith("/chronicle")
    },
    {
      title: "Counterfactual",
      href: "/counterfactual",
      isActive: location.pathname.startsWith("/counterfactual")
    },
    {
      title: "Colophon",
      href: "/colophon",
      isActive: location.pathname.startsWith("/colophon")
    }
  ];

  useEffect(() => {
    if (!isNavOpen) return;
    const handleClickOutside = (e) => {
      if (
        e.target.isConnected &&
        !e.target.closest(`.${styles.nav}`.replace(/\+/g, "\\+"))
      )
        onHideNav();
    };
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, [isNavOpen, onHideNav]);

  return (
    <div className={styles.root}>
      <nav
        className={cn(styles.nav, isNavOpen && styles.showNav)}
        aria-labelledby="site_nav_menu_button"
        id="site_nav_menu"
        role="menu"
        {...extraProps}
      >
        {isNavOpen && <CButton cycleIdx={cycleIdx} ref={cycleRef} />}
        <ul className={boxUl}>
          {pages.map((page) => (
            <li key={page.title}>
              <Link
                to={page.href}
                onClick={onHideNav}
                className={page.isActive ? boxLinkInvert : ""}
              >
                {page.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      {!isNavOpen && <CButton cycleIdx={cycleIdx} ref={cycleRef} />}
    </div>
  );
};

export default Nav;
