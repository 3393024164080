import React, { createContext, useState } from "react";

const LayoutContext = createContext();
export default LayoutContext;

export function LayoutProvider({ children }) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        isNavOpen,
        setIsNavOpen,
        isLightboxOpen,
        setIsLightboxOpen
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}
