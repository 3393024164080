const React = require("react");

const { LayoutProvider } = require("./src/contexts/layout");
const Layout = require("./src/components/layout").default;

exports.wrapRootElement = ({ element }) => {
  return <LayoutProvider>{element}</LayoutProvider>;
};

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
